import { AxiosPromise } from "axios";
import { getAuthToken } from "lib/use-auth";
import useSWR, { SWRConfiguration } from "swr";
import { ApiService } from "./api.service";
import { ActiveSubscriptionResponse } from "./subscriptions.service";
import { ModelProvider } from "./playground.service";
import { User } from "@/types/app/user";

export const enum UserOrganizationRole {
  admin = "admin",
  member = "member",
  developer = "developer",
}

export const enum UserTeamRole {
  admin = "admin",
  member = "member",
}

export interface TeamEmojiIcon {
  emoji: string;
  hue: string;
}

/**
 * Base team type that omits the list of users.
 * Used as the Team type for lists of teams under a user.
 */
export interface BaseTeam {
  id: string;
  name: string;
  description: string | null;
  organization_id: string;
  icon: TeamEmojiIcon | null;
  private: boolean;
  created_at: string;
  updated_at: string;
}

export interface TeamUser extends User {
  role: UserTeamRole;
}

/**
 * Team with a list of users.
 */
export interface Team extends BaseTeam {
  users: TeamUser[];
}

export interface OrganizationUser extends User {
  role: UserOrganizationRole;
}

export interface UrlIcon {
  url: string;
}

export interface BaseOrganization {
  id: string;
  name: string;
  slug: string | null;
  icon: UrlIcon | null;
  active_subscription: ActiveSubscriptionResponse | null;
  feature_flags: Record<string, boolean> | null;
  customer_id: string | null;
  created_at: string;
  updated_at: string;
  members: number;
  tag: string | null;
}

export interface Organization extends BaseOrganization {
  // teams: Team[];
  users: OrganizationUser[];
}

export const useOrganization = (
  organizationId: string | null | undefined,
  swrOptions: SWRConfiguration<Organization> = {},
) => {
  const { data, error, mutate } = useSWR<Organization>(
    organizationId ? [`/v4/organizations/${organizationId}`, getAuthToken()] : null,
    swrOptions,
  );
  return { organization: data, error: error, loading: !data && !error, mutate };
};

export type CreateOrganizationRequest = Pick<BaseOrganization, "name" | "slug">;

export const createOrganization = (request: CreateOrganizationRequest): AxiosPromise<Organization> => {
  return ApiService.post(`/v4/organizations`, request);
};

export type UpdateOrganizationRequest = Partial<Pick<BaseOrganization, "name" | "slug">>;

export const updateOrganization = (
  organizationId: string,
  update: UpdateOrganizationRequest,
): AxiosPromise<Organization> => {
  return ApiService.patch(`/v4/organizations/${organizationId}`, update);
};

const blobFetcher = async (url: string) => {
  const response = await ApiService.get(url, { responseType: "blob" });
  return response.data;
};

export const useOrganizationIcon = (
  organizationId: string | null,
  swrOptions: SWRConfiguration<any> = { revalidateOnFocus: false, dedupingInterval: 10000 },
) => {
  const { data, error, mutate } = useSWR<any>(
    organizationId !== null ? `/v4/organizations/${organizationId}/icon` : null,
    blobFetcher,
    {
      // Don't retry on error as we expect a 404 if the icon doesn't exist.
      // Could customize per status code, but this is sufficient.
      shouldRetryOnError: false,
      ...swrOptions,
    },
  );
  return { data, error: error, loading: !data && !error, mutate };
};

export const deleteOrganizationIcon = (organizationId: string): AxiosPromise<void> => {
  return ApiService.remove(`/v4/organizations/${organizationId}/icon`);
};

export const setOrganizationIcon = (organizationId: string, file: File): AxiosPromise<Organization> => {
  const formData = new FormData();
  formData.append("file", file, file.name);

  return ApiService.put(`/v4/organizations/${organizationId}/icon`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateOrganizationUserRole = (
  organizationId: string,
  userId: string,
  role: UserOrganizationRole,
): AxiosPromise<void> => {
  return ApiService.put(`/v4/organizations/${organizationId}/users/${userId}/role`, { role });
};

export const removeUserFromOrganization = (organizationId: string, userId: string): AxiosPromise<void> => {
  return ApiService.remove(`/v4/organizations/${organizationId}/users/${userId}`);
};

export interface ApiKey {
  id: string;
  name: string;
  api_key: string;
  organization_id: string;
  created_by: User;
  last_used: string | null;
  created_at: string;
}

export const useOrganizationApiKeys = (
  organizationId: string | undefined | null,
  swrOptions: SWRConfiguration<ApiKey[]> = {},
) => {
  const { data, error, mutate } = useSWR<ApiKey[]>(
    organizationId ? [`/v4/organizations/${organizationId}/api-keys`, getAuthToken()] : null,
    swrOptions,
  );
  return { apiKeys: data, error: error, loading: !data && !error, mutate };
};

interface CreateApiKeyRequest {
  name: string;
  organization_id: string;
}

export const createApiKey = (request: CreateApiKeyRequest): AxiosPromise<ApiKey> => {
  return ApiService.post(`/v4/api-keys`, request);
};

export const revokeApiKey = (id: string): AxiosPromise<void> => {
  return ApiService.remove(`/v4/api-keys/${id}`);
};

export const useTeam = (teamId: string, swrOptions: SWRConfiguration<Team> = {}) => {
  const { data, error, mutate } = useSWR<Team>([`/v4/teams/${teamId}`, getAuthToken()], swrOptions);
  return { team: data, error: error, loading: !data && !error, mutate };
};

export const useTeams = (swrOptions: SWRConfiguration<BaseTeam[]> = {}) => {
  const { data, error, mutate } = useSWR<BaseTeam[]>([`/v4/teams`, getAuthToken()], swrOptions);
  return { teams: data, error: error, loading: !data && !error, mutate };
};

export type CreateTeamRequest = Pick<BaseTeam, "name" | "description" | "organization_id">;

export const createTeam = (request: CreateTeamRequest): AxiosPromise<Team> => {
  return ApiService.post(`/v4/teams`, request);
};

export type UpdateTeamRequest = Partial<Pick<BaseTeam, "name" | "description">> & { icon?: TeamEmojiIcon };

export const updateTeam = (teamId: string, update: UpdateTeamRequest): AxiosPromise<Team> => {
  return ApiService.patch(`/v4/teams/${teamId}`, update);
};

interface InviteUserToOrganizationRequest {
  email_address: string;
  role: UserOrganizationRole;
}

interface InviteUserToOrganizationResponse {
  user_id: string;
}

export const inviteToOrganization = (
  organizationId: string,
  request: InviteUserToOrganizationRequest,
): AxiosPromise<InviteUserToOrganizationResponse> => {
  return ApiService.post(`/v4/organizations/${organizationId}/invite`, request);
};

interface InviteUserToTeamRequest {
  email_address: string;
  role: UserTeamRole;
}

interface InviteUserToTeamResponse {
  user_id: string;
}

export const inviteToTeam = (
  teamId: string,
  request: InviteUserToTeamRequest,
): AxiosPromise<InviteUserToTeamResponse> => {
  return ApiService.post(`/v4/teams/${teamId}/invite`, request);
};

export type ModelProviderWithStorableKey =
  | ModelProvider.openai
  | ModelProvider.anthropic
  | ModelProvider.cohere
  | ModelProvider.openai_azure
  | ModelProvider.google
  | ModelProvider.bedrock;

export interface OrganizationModelProviderKey {
  model_provider: ModelProviderWithStorableKey;
  key: string;
  azure_endpoint?: string;
  aws_region?: string;
  aws_secret_access_key?: string;
  created_by: User;
  last_used: string | null;
  created_at: string;
}

export interface OrganizationEmailDomains {
  email_domains: string[];
}

/*
 * Returns the the model provider keys stored for the organization.
 * NB: This does not consider the user's local keys they might have in their local storage.
 */
export const useModelProviderKeys = (
  organizationId?: string,
  swrOptions: SWRConfiguration<OrganizationModelProviderKey[]> = {},
) => {
  const { data, error, mutate } = useSWR<OrganizationModelProviderKey[]>(
    organizationId ? [`/v4/organizations/${organizationId}/model-provider-keys`, getAuthToken()] : null,
    swrOptions,
  );
  return { keys: data, error: error, loading: !data && !error, mutate };
};

export const updateModelProviderKeys = (
  organizationId: string,
  request: {
    model_provider: ModelProviderWithStorableKey;
    key?: string;
    azure_endpoint?: string;
    aws_region?: string;
    aws_secret_access_key?: string;
  }[],
): AxiosPromise<OrganizationModelProviderKey[]> => {
  return ApiService.patch(`/v4/organizations/${organizationId}/model-provider-keys`, request);
};

export const deleteModelProviderKey = (
  organizationId: string,
  provider: ModelProviderWithStorableKey,
): AxiosPromise<void> => {
  return ApiService.remove(`/v4/organizations/${organizationId}/model-provider-keys/${provider}`);
};

export const useAllowedEmailDomains = (
  organizationId: string,
  swrOptions: SWRConfiguration<OrganizationEmailDomains> = {},
) => {
  const { data, error, mutate } = useSWR<OrganizationEmailDomains>(
    organizationId ? [`/v4/organizations/${organizationId}/allowed-email-domains`, getAuthToken()] : null,
    swrOptions,
  );
  return { emailDomains: data, error: error, loading: !data && !error, mutate };
};

export const createAllowedEmailDomain = (organizationId: string, email_domain: string): AxiosPromise<string> => {
  return ApiService.post(`/v4/organizations/${organizationId}/allowed-email-domains`, {
    email_domains: [email_domain],
  });
};

export const deleteAllowedEmailDomain = (organizationId: string, email_domain: string): AxiosPromise<void> => {
  return ApiService.remove(
    `/v4/organizations/${organizationId}/allowed-email-domains/${encodeURIComponent(email_domain)}`,
  );
};

export const joinOrganization = (organizationId: string): AxiosPromise<Organization> => {
  return ApiService.post(`/v4/organizations/${organizationId}/join`);
};
