import { FileType } from "@/types/app/file";

export const ROOT_DIRECTORY_NAME = "__ROOT__";

export const FEATURES = {
  // Whether to show label descriptions - not all parts of our UI have
  // these right now
  SHOW_LABEL_DESCRIPTIONS: false,

  // Warn for non-blink browsers (eg, not Chrome, not Edge)
  WARN_OTHER_BROWSERS: false,
};

// Placeholder value required even when disabling hotkeys if shortcut is not set
// (undefined, empty string or null) it seems to stop all other hotkeys from working.
// This variable should always be used alongside {enabled: false}.
// This results in: 1. the callback being properly unbound, and 2. other hotkeys continue functioning.
export const PLACEHOLDER_HOTKEY = "num_add";

export const API_URL = "https://api.humanloop.com"; // NB: best to use NEXT_PUBLIC_API_URI for the server setting.
export const SLACK_LINK =
  "https://join.slack.com/t/humanloop-community/shared_invite/zt-15bpaukat-w99Ns5wyQiuKVPSxyLqHlQ";
export const ZAPIER_LINK = "https://zapier.com/apps/humanloop/integrations";

// Docs
export const DOCS_LINK = "https://humanloop.com/docs/";
export const DOCS_CHANGELOG = "https://humanloop.com/docs/changelog";
export const DOCS_API_REFERENCE = "https://humanloop.com/docs/api-reference/";

// Docs - concepts / topics
export const DOCS_ACCESS_ROLES = "https://humanloop.com/docs/reference/access-roles";
export const DOCS_FILES = "https://humanloop.com/docs/explanation/files";
export const DOCS_PROMPTS = "https://humanloop.com/docs/explanation/prompts";
export const DOCS_DATASETS = "https://humanloop.com/docs/explanation/datasets";
export const DOCS_EVALUATORS = "https://humanloop.com/docs/explanation/evaluators";
export const DOCS_TOOLS = "https://humanloop.com/docs/explanation/tools";
export const DOCS_FLOWS = "https://humanloop.com/docs/explanation/flows";
// Docs - guides
export const DOCS_GENERATE_AND_LOGGING = "https://humanloop.com/docs/guides/prompts/call-prompt";
export const DOCS_LOG_PROMPT = "https://humanloop.com/docs/guides/prompts/log-to-a-prompt";
export const DOCS_UPLOAD_DATA = "https://humanloop.com/docs/guides/evals/create-dataset";
export const DOCS_SESSIONS = "https://humanloop.com/docs/v4/guides/logging-session-traces";
export const DOCS_EVALUATIONS = "https://humanloop.com/docs/guides/evals/overview";
export const DOCS_EVALUATORS_SELF_HOSTED = "https://humanloop.com/docs/guides/evals/code-based-evaluator";
export const DOCS_EVALUATORS_HUMAN = "https://humanloop.com/docs/guides/evals/human-evaluators";
export const DOCS_EVALUATORS_LLM = "https://humanloop.com/docs/guides/evals/llm-as-a-judge";
export const DOCS_RUN_EVALUATIONS = "https://humanloop.com/docs/guides/evals/run-evaluation";
export const DOCS_MONITORING = "https://humanloop.com/docs/guides/observability/monitoring";

// Docs - deprecated
export const DOCS_FINETUNING = "https://humanloop.com/docs/v4/guides/finetune-a-model";

// Docs - API
export const DOCS_API_PROMPTS = "https://humanloop.com/docs/v5/api-reference/prompts";
export const DOCS_API_TOOLS = "https://humanloop.com/docs/v5/api-reference/tools/log";
export const DOCS_API_DATASETS = "https://humanloop.com/docs/v5/api-reference/datasets";
export const DOCS_API_EVALUATORS = "https://humanloop.com/docs/v5/api-reference/evaluators";
export const DOCS_API_FLOWS = "https://humanloop.com/docs/v5/api-reference/flows";
export const DOCS_API_DIRECTORIES = "https://humanloop.com/docs/v5/api-reference/directories/list";

export const FILETYPE_TO_DOCS_API_URL: Record<FileType | "directory", string> = {
  prompt: DOCS_API_PROMPTS,
  tool: DOCS_API_TOOLS,
  dataset: DOCS_API_DATASETS,
  evaluator: DOCS_API_EVALUATORS,
  flow: DOCS_API_FLOWS,
  directory: DOCS_API_DIRECTORIES,
};

export const OPENAI_API_KEYS_URL = "https://platform.openai.com/api-keys";

export const PLACEHOLDER_DASHES = "– –";

export const UNSET_FEEDBACK = "__UNSET__";

export const PRICING_URL = "https://humanloop.com/pricing";

export const SALES_CALL_LINK = "https://humanloop.com/demo";

export const CHANGELOG_LINK = "https://docs.humanloop.com/changelog";
export const CHANGELOG_RSS = "https://docs.humanloop.com/changelog.rss";

export const ACCOUNT_SETTINGS_URL = "/account/settings";

export const FORGOT_PASSWORD_URL = "/forgot-password";
export const LOGIN_URL = "/login";

export const NOT_FOUND_URL = "/404";

export const SIGNUP_URL = "/signup";
export const SIGNUP_CREATE_ORGANIZATION_URL = "/signup/create-organization";
export const SIGNUP_DETAILS_URL = "/signup/details";
export const SIGNUP_INVITE_URL = "/signup/invite";
export const SIGNUP_WELCOME_URL = "/signup/welcome";

export const TOOLS_URL = "/tools";

export const LIBRARY_URL = "/library";

// This is intended to have no conflicts with user generated sources because
// it is a magical value that will cause any generations to be logged to playground
// history and won't cause model configs to be created.
export const HUMANLOOP_PLAYGROUND_SOURCE_SECRET = "__humanloop-playground";
// TODO: remove in favour of inspecting if there are any project model configs with endpoint=chat
export const PROJECT_INPUTS_MESSAGES_FIELD = "messages";

// ID prefixes for different types of objects
export const DIRECTORY_ID_PREFIX = "dir_";
export const PROMPT_ID_PREFIX = "pr_";
export const TOOL_ID_PREFIX = "tl_";
export const DATASET_ID_PREFIX = "ds_";
export const EVALUATOR_ID_PREFIX = "ev_";
export const FLOW_ID_PREFIX = "fl_";
export const EVALUATOR_LEGACY_ID_PREFIX = "evfn_";
export const DATASET_LEGACY_ID_PREFIX = "evts_";
export const PROMPT_VERSION_ID_PREFIX = "prv_";
export const TOOL_VERSION_ID_PREFIX = "tlv_";
export const DATASET_VERSION_ID_PREFIX = "dsv_";
export const EVALUATOR_VERSION_ID_PREFIX = "evv_";
export const FLOW_VERSION_ID_PREFIX = "flv_";
export const CONFIG_ID_PREFIX = "config_";

export const FILE_ID_PREFIXES = [
  PROMPT_ID_PREFIX,
  TOOL_ID_PREFIX,
  DATASET_ID_PREFIX,
  DATASET_LEGACY_ID_PREFIX,
  EVALUATOR_ID_PREFIX,
  EVALUATOR_LEGACY_ID_PREFIX,
];
