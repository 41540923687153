/* eslint-disable @next/next/no-html-link-for-pages */
import { MessageUs } from "../library/MessageUs";
import { InlineLink } from "../library/InlineLink";
import { ArrowRightIcon } from "../library/Icons";
import { classNames } from "../../lib/utils";

interface Props {
  title: string;
  text: string | JSX.Element;
  statusCode: number;
  inset?: boolean;
}

const ErrorPage = ({ title, text, statusCode, inset = false }: Props) => {
  return (
    <div
      className={classNames(
        inset ? "flex h-full w-full items-center justify-center" : "grid h-screen w-screen items-center justify-center",
      )}
    >
      <div className="prose max-w-screen-sm p-8">
        {statusCode}
        <h1 className="font-display text-32-32 font-bold text-text-base-1">{title}</h1>
        <p className=" text-16-24">{text}</p>
        <p className="not-prose text-16-24">
          If you think something is wrong please <MessageUs />.
        </p>
        <p className="not-prose mt-32 text-16-24">
          {/* Avoiding the next Link href because it doesn't play well with the useEffect usePageRedirectToOrgSlug
          // so the url changes but the user doesn't get redirected */}
          <a href="/">
            <InlineLink IconRight={ArrowRightIcon}>Home</InlineLink>
          </a>
        </p>
      </div>
    </div>
  );
};
export default ErrorPage;
